import { render, staticRenderFns } from "./Testimonial610.vue?vue&type=template&id=65075e1a&scoped=true"
import script from "./Testimonial610.vue?vue&type=script&lang=js"
export * from "./Testimonial610.vue?vue&type=script&lang=js"
import style0 from "./Testimonial610.vue?vue&type=style&index=0&id=65075e1a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65075e1a",
  null
  
)

/* custom blocks */
import block0 from "@/locales/testimonial610.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Ftestimonial%2FTestimonial610.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports